/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import * as R from 'ramda';
import qs from 'qs';
import _axios from 'axios';
import _ from 'lodash';
import bPromise from 'bluebird';
import { INotarySession, INotarySessionStatus, IParticipant } from '../';

interface TermsPayload {
  accepted: boolean;
  econsentPdfUrl: string;
  privacyPolicyPdfUrl: string;
  termsAndConditionsPdfUrl: string;
}

export const consumerRoomApi = () => {
  const axios = (window as any).axios = _axios.create({
    headers: {
      'x-isiframe': true,
    }
  });

  const api = {
    get: axios.get,
    post: axios.post,
    patch: axios.patch,

    getInitialData: async (requestId: string, nsId: string) => {
      const { ns, status, whitelabelId, docs } = await bPromise.props({
        ns: api.getNs(nsId),
        status: api.getNsStatus(nsId),
        whitelabelId: api.getWhitelabelId(),
        docs: api.getNsDocuments(nsId)
          .then((d) =>
            _.map(d, (doc: Record<string, any>) => {
              return ({
              ...doc,
              url: doc.url || `/consumer/api/notarization/${nsId}/document?orig=1&type=buffer&docId=${doc.id}`,
            })})
          ),
      });

      return {
        // status,
        notarySession: ns,
        status: ns.status,
        notary: ns.notary,
        whitelabelId,
        signers: R.indexBy(R.prop('id'), [status.participant, ..._.get(status, 'participants')]),
        // twilio,
        docs,
      };
    },

    enoteConsent: async (nsId: string, nsUserId: string, data?: { ssn: string, email?: string, phone?: string }) => axios.post(`/consumer/api/integration/eoriginal/${nsId}/consent/${nsUserId}`, data),

    kbaVerificationTimeout: async (id: string, nsuId: string) => axios.post(`/consumer/api/notarization/${id}/participants/${nsuId}/id-verify-timeout`),

    checkKbaCompliance: (id: string) => axios({
      method: 'PATCH',
      url: `/consumer/api/compliance/${id}/kba-check`,
    }),

    isValidCoupon: async (code: string, userId: string) => axios.post('/consumer/api/coupon/valid', {
    }, { headers: { code, userId } }),

    postVADisclaimer: async (id: string, nsuId: string, data: Record<string, any>) => await axios({ url: `/consumer/api/notarization/${id}/participants/${nsuId}/va-disclaimer?source=notary-room`, method: 'POST', data })
      .then(R.prop('data')),

    // the id returned from this endpoint is used for org->enl for the firebase rtdb path /organization/:whitelabelId/{sessions,rooms}
    getWhitelabelId: async () => await axios.get<{ id: string }>(`/consumer/api/whitelabel`).then(R.prop('data')).then(R.prop('id')),

    getParticipant: async (nsId: string, nsUserId: string) => await axios.get(`/consumer/api/notarization/${nsId}/participants/${nsUserId}`).then(R.prop('data')) as IParticipant,

    getParticipants: async (nsId: string) => await axios.get(`/consumer/api/notarization/${nsId}/participants`).then(R.prop('data')) as IParticipant[],

    sendEvent: async ({ type, data }: { type: string, data: any }) => await axios({
      method: 'PATCH',
      url: '/consumer/api/notarization/event',
      data: { type, data },
    }).then(R.prop('data')),

    postSecondaryId: (id: string, nsuId: string, payload?: FormData) => axios({
      method: 'POST',
      url: `/consumer/api/notarization/${id}/participants/${nsuId}/secondary-id`,
      data: payload,
      headers: { 'Content-Type': 'multipart/form-data' }
    }),

    getTwilioTokenForUser: async (nsId: string, nsUserId: string) => await axios.get(`/consumer/api/notarization/${nsId}/participants/${nsUserId}/video`).then(R.prop('data')),

    getConferenceTokenForUser: async (nsId: string, nsUserId: string, deviceId: string) => {
      const { data } = await axios.get(
        `/consumer/api/notary-session/${nsId}/conference/token?deviceId=${deviceId}&participantId=${nsUserId}`
      );

      return data;
    },

    addParticipantToConference: async (
      nsId: string,
      nsUserId: string,
      deviceId: string,
      token: string
    ) => {
      const { data } = await axios.post(
        `/consumer/api/notary-session/${nsId}/conference/device/${deviceId}/participant?participantId=${nsUserId}`,
        { token }
      );

      return data;
    },

    getNs: async (nsId: string) => await axios.get(`/consumer/api/notarization/${nsId}`).then(R.prop('data')) as INotarySession,

    getNsUserByPin: async (requestId: string, pinCode: string, termsAccepted: boolean) => await axios.get(`/consumer/api/notarization/room/${requestId}/pin-code/${pinCode}?kbaTermsAccepted=${termsAccepted ? 'true' : 'false'}`)
      .then(R.prop('data')) as IParticipant,

    getNsStatus: async (nsId: string) => await axios.get(`/consumer/api/notarization/${nsId}/status`)
      .then(R.prop('data')) as INotarySessionStatus,

    getNsDocuments: async (nsId: string, params: Record<string, any> = {}) => await axios({
      url: `/consumer/api/notarization/${nsId}/docs`,
      method: 'GET',
      params: {
        ...params,
      },
    }).then(R.prop('data')),

    getNotarizationInvoice: (nsId: string, hasRequestedCopies: boolean, couponCode: string, userId: string) => axios.get(`/consumer/api/notarization/${nsId}/payments?hasRequestedCopies=${hasRequestedCopies}&couponCode=${couponCode}&userId=${userId}&noIntent=1`).then(R.prop('data')),

    postNotarizationPayment: (nsId: string, data: any) => axios.post(`/consumer/api/notarization/${nsId}/payments`, data),

    submitTermsAgreement: async (nsId: string, nsUserId: string, payload: TermsPayload) => await axios({
      method: 'PATCH',
      url: `/consumer/api/notarization/${nsId}/participants/${nsUserId}/terms`,
      data: {
        ...payload
      },
    }),

    initVerification: async (id: string, nsuId: string, data: Record<string, any>, provider?: string) => axios({
      method: 'POST',
      url: `/consumer/api/notarization/${id}/participants/${nsuId}/id-verify-start`,
      params: { provider },
      data,
    }),

    getNotarizationStatus: async (nsId: string) => axios({
      url: `/consumer/api/notarization/${nsId}/status`,
      method: 'GET',
    }).then(R.prop('data')),

    getIdVerificationQuestions: async (nsId: string, nsuId: string) => axios.get(`/consumer/api/notarization/${nsId}/participants/${nsuId}/id-verify-questions`)
      .then(R.prop('data')),

    postIdVerificationAnswers: (id: string, nsuId: string, data: Record<string, any>) => axios.post(`/consumer/api/notarization/${id}/participants/${nsuId}/id-verify-answers`, data),

    getDocVerifyUrl: (nsId: string, nsuId: string, opts = {}) => {
      const query = qs.stringify(opts);

      return axios({
        url: `/consumer/api/notarization/${nsId}/participants/${nsuId}/document-verification?${query}`,
        method: 'GET',
      })
    },

    getRetryDocVerifyUrl: (nsId:string, nsuId:string) => axios.get(`/consumer/api/notarization/${nsId}/participants/${nsuId}/document-verification-retry`),

    failNotarization: (nsId: string, nsUserId: string, reason: string) => axios({
      method: 'POST',
      url: `/consumer/api/notarization/${nsId}/fail`,
      data: { reason, nsUserId },
    }),
  };

  return api;
};

export default consumerRoomApi;
